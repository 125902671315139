/* Based on:
 *
 * Mailchimp AJAX form submit VanillaJS
 * Vanilla JS
 * Author: Michiel Vandewalle
 * Github author: https://github.com/michiel-vandewalle
 * Github project: https://github.com/michiel-vandewalle/Mailchimp-AJAX-form-submit-vanillaJS
 */

(function () {
  var mailchimpForms = document.getElementsByClassName('js-subscribe-form');
  [].forEach.call(mailchimpForms, function (form) {
    // Get/create div to display response
    var response;
    if (form.getElementsByClassName('js-subscribe-response').length > 0) {
      response = form.getElementsByClassName('js-subscribe-response')[0];
    } else {
      response = document.createElement('DIV');
      response.classList.add('subscribe__response');
      form.appendChild(response);
    }

    form.addEventListener('submit', function (e) {
      e.preventDefault();

      // Reset validation classes
      form.classList.remove('subscribe-form-is-invalid');
      form.classList.remove('subscribe-form-is-valid');

      // Check for spam
      // if (form.getElementById('js-validate-robot').value !== '') return false;

      // Get url for mailchimp
      var url = this.action.replace('/post?', '/post-json?');

      // Add form data to object
      var data = '';
      var inputs = form.getElementsByTagName('input');
      [].forEach.call(inputs, function (input) {
        data += '&' + input.name + '=' + encodeURIComponent(input.value);
      });

      // Create & add post script to the DOM
      var script = document.createElement('script');
      script.src = url + data;
      document.body.appendChild(script);

      // Callback function
      var callback = 'callback';
      window[callback] = function (mcresponse) {
        // Remove post script from the DOM
        delete window[callback];
        document.body.removeChild(script);

        // Display response message
        response.innerHTML = mcresponse.msg.replace(/^\d*\s[-—–]\s/g, '');

        // Add validation classes
        if (mcresponse.result === 'error') form.classList.add('subscribe-form-is-invalid');
        else form.classList.add('subscribe-form-is-valid');
      };
    });
  });
})();
